import React from 'react';
import { Button,  Divider, Box, Modal, Card, CardHeader, Stack, IconButton,  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { handleDownload } from './downloadFile';
import pdf from '../../assets/img/pdf.png'
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import ModalCss from "../../styles/modal.module.scss";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { formatDateString } from '../../utils/dateFormater';

export default function ViewUploadedOrderInvoiceModal({ openInvoice, handleCloseInvoice , invoice}) {
    const closeTheModal = () => {
        handleCloseInvoice();
    }
    return (
        <Modal open={openInvoice} onClose={closeTheModal} className={ModalCss.customModal}>
            <Card className={`modal ${ModalCss.customModal_lg}`}>
                <CardHeader sx={{ textAlign: "center" }} title="Uploaded Invoice" />
                {/* <Typography component="div" sx={{ textAlign: "center", marginTop: 2, marginBottom: 2 }}>
                    You can view your uploaded invoice here
                </Typography> */}
                <Divider sx={{ width: '100%', mt: 1, mb: 2 }} />
                <Box
                    component="div"
                    sx={{
                        maxWidth: "800px",  // Adjusted max-width for better fit
                        margin: "0 auto",
                        textAlign: "center",
                        padding: "20px",
                    }}
                >
                    <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
                        <IconButton onClick={closeTheModal}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Stack>
                    <Stack
                        justifyContent="center"
                        direction="column"
                        gap={2}
                        alignItems="center"
                        fontSize={"18px"}
                        boxSizing={"border-box"}
                        component={"div"}
                    >
                        <TableContainer component={Paper} sx={{ maxWidth: "100%", marginTop: 2 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>File</TableCell>
                                        <TableCell align='center'>Invoice Amount</TableCell>
                                        <TableCell align='center'>Created Date</TableCell>
                                        <TableCell align='center'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoice.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align='center'>
                                                <img width={30} height={30} src={pdf} style={{ marginTop: "10px", marginBottom: "10px" }} alt="PDF File" />
                                            </TableCell>
                                            <TableCell align='center'>{item.invoice_amount}</TableCell>
                                            <TableCell align='center'>{formatDateString(item.invoice_uploaded_on)}</TableCell>
                                            <TableCell align='center'>
                                                <IconButton 
                                                
                                                 //onClick={() => handleDownload(item.upload_invoice)}
                                                 onClick={() => handleDownload(new Date() + 'invoice', item.upload_invoice)}
                                                 >
                                                    <DownloadForOfflineRoundedIcon />
                                                </IconButton>
                                                <IconButton onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${item.upload_invoice}`)}>
                                                    <FullscreenIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                </Box>
            </Card>
        </Modal>
        
    );
}