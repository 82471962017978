import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { convertToFormData } from '../convertFormData';
import { getRevisedGFCApi } from '../../store/home/getRevisedGFC';
import { convertToDateFormatYearWithTwoDigits } from '../../utils/dateFormater';
import { handleDownload } from '../reuseable/downloadFile';
import { postRevisedGFCView } from '../../store/home/postRevisedGFCView';
import { showToast } from '../../toast/toast';
import Loader from '../reuseable/Loader';
import DownloadFileModal from '../reuseable/DownloadFileModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
export default function GfcRevised({ formID , setSelectedTab }) {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const {result : revisedGFCList, loading:GFCLoading} = useSelector((state) => state?.revisedGFC);

    const getRevisedGFC = async () => {
        const formD = {
            'form_id': formID
        }
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(getRevisedGFCApi(data));
            setData(response?.payload?.data);
        } catch (error) {
            showToast(error, 2)
        }
    }

    const handleView = async (data) => {
        try {
            localStorage.setItem("isRevisedGFCViewClick", 1);
            await dispatch(postRevisedGFCView(data));
            
            setSelectedTab(5)
        } catch (error) {
            showToast(error, 2)
        }

    }

    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const [downloadFileArray, setDownloadFileArray] = useState([]); 
    const [type, setType] = useState('');
    const showDownloadFile = (data, type) => {
        setType(type);
        setDownloadFileArray(data);
        setOpenDownloadModal(true);
    }
    
    const closeDownloadModal = () => {
        setDownloadFileArray([]);
        setOpenDownloadModal(false);
    }

    useEffect(() => {
        getRevisedGFC();
    }, [formID]);

    if(GFCLoading) return <Loader />

    return (
        <>
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h6" className="fs-17  fw-400">GFC Revisions</Typography>
                </Grid>
                {!GFCLoading && revisedGFCList?.length === 0 && (
                    <>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                            <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell sx={{ background: "#85949F !important" }}>Schematic Sent Date</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell align="center">
                                                No data found
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </>
                )}
                {!GFCLoading && revisedGFCList?.length > 0 && (
                    <>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                            <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell sx={{ background: "#85949F !important" }}>Schematic Sent Date</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {revisedGFCList.map((row) => (
                                            <StyledTableRow key={row.id}>
                                                <StyledTableCell component="th" scope="row">
                                                    {convertToDateFormatYearWithTwoDigits(row?.created_at)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button className="DownloadBtn" sx={{ marginRight: 1 }} onClick={() =>  handleView(row)}>
                                                        <RemoveRedEyeRoundedIcon fontSize='small'  />View
                                                    </Button>
                                                    <Button className="DownloadBtn" sx={{ marginRight: 1 }} onClick={() => showDownloadFile(row?.gfc_cad, 'CAD')}>
                                                        <DownloadForOfflineRoundedIcon fontSize='small' />CAD
                                                    </Button>
                                                    <Button className="DownloadBtn" onClick={() => showDownloadFile(row?.gfc_pdf, 'PDF')}>
                                                        <DownloadForOfflineRoundedIcon fontSize='small' />PDF
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
        <DownloadFileModal openView={openDownloadModal} handleCloseView={closeDownloadModal} fileArray={downloadFileArray} type={type} />
        </>
    );
}