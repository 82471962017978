import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import InputDrawing from '../../assets/img/InputDrawingicon.png'
import Style from "../../styles/home.module.scss";
import SchematicLayout from '../../assets/img/SchematicLayouticon.png'
import { getInventoryApi } from "../../store/inventory";
import { useDispatch, useSelector } from "react-redux";
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';


const DashboardInventoryBlock = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {loading:inventoryLoading, error:inventoryError, result:inventoryList}  = useSelector(state => state?.inventoryReducer);

    useEffect(() => {
        dispatch(getInventoryApi())
    },[])
    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card sx={{boxShadow: "0px 4px 24px 0px #E0F0FF !important"}}>            
                  <Box className={Style?.headerSectionWhite}>
                    <Typography className={Style?.cardHeadingWhite} variant="h6">
                      Inventory Management
                    </Typography>
                  </Box>

                  <Stack sx={{ mt: 2 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Card className={Style?.homeCard4} sx={{height:"calc(100% - 31PX)"}} onClick={() => navigate("/inventory")} style={{cursor:"pointer"}}>
                          <Stack direction="row" alignItems="center" spacing={1.5}>
                            <InventoryOutlinedIcon />
                            <Stack>
                              <Typography className={Style.cardTitle} variant="p">
                                Total Products
                              </Typography>
                              <Typography className={Style.cardValue} variant="h5">
                                {inventoryList?.data?.length ? inventoryList?.data?.length : 0}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Card>
                      </Grid>
                    </Grid>
                  </Stack>
                </Card>      
              </Grid>
        </>
    )

}   

export default DashboardInventoryBlock;