import { combineReducers } from "@reduxjs/toolkit";
import { authOtpViaEmailReducer } from "./auth/authOtpViaEmail";
import { authOtpViaSMSReducer } from "./auth/authOtpViaSMS";
import { currentDealorReducer } from "./auth/currentDealor";
import { dealorShipLocationsReducer } from "./auth/dealorShipLocations";
import { forgetPasswordViaEmailReducer } from "./auth/forgetPasswordViaEmail";
import { forgetPasswordViaSMSReducer } from "./auth/forgetPasswordViaSMS";
import { loginReducer } from "./auth/login";
import { resetPasswordReducer } from "./auth/resetPassword";
import { resetPasswordVerifyOtpReducer } from "./auth/resetPasswordVerifyOtp";
import { updateDealorReducer } from "./auth/updateDealor";
import { verifyAuthOtpReducer } from "./auth/verifyAuthOtp";
import { billInvoicesReducer } from "./orders/billInvoices";
import { dispatchStatusCountReducer } from "./orders/dispatchStatusCount";
import { notificationCountReducer } from "./orders/notificationCount";
import { orderDetailsReducer } from "./orders/orderDetails";
import { ordersReducer } from "./orders/orders";
import { ordersCountReducer } from "./orders/ordersCount";
import { pendingOrdersCountReducer } from "./orders/pendingOrdersCount";
import { placeOrderReducer } from "./orders/placeOrder";
import { uploadInvoiceReducer } from "./orders/uploadInvoice";
import { prodcutCRUDReducer } from "./products/productCRUD";
import { productsReducer } from "./products/products";
import {addApplicantReducer} from "./dealorOnBoarding/addApplicant"
import {getApplicantSliceReducer} from "./dealorOnBoarding/getApplicant"
import { getEmailTempleteReducer } from "./dealorOnBoarding/emailTemplete";
import { logoutReducer } from "./auth/logout";
import { currentUserReducer } from "./user/currentUser";
import { getUserDetailReducer } from "./user/getUserDetail";
import { inputDrawingReducer } from "./home/layoutTable";
import { schematicLayoutByClusterReducer } from "./home/getSchematicLayout";
import { postSchematicLayoutSliceReducer } from "./home/postSchematicLayout";
import { designEstimateReducer } from "./home/getDesignEstimate";
import { getLayoutFeedbackReducer } from "./home/getLayoutFeedback";
import { getAllFeedbackReducer } from "./home/getAllFeedback";
import { GFCReducer } from "./home/getGFC";
import { postGFCReducer } from "./home/postGFC";
import { revisedLayoutReducer } from "./home/getRevisedLayout";
import { revisedGFCReducer } from "./home/getRevisedGFC";
import { revisedLayoutViewReducer } from "./home/postRevisedLayoutView";
import { revisedGFCViewReducer } from "./home/postRevisedGFCView";
import { sendInvoiceReducer } from "./home/sendInvoice";
import { supplierReducer } from "./supplier";
import { categoryReducer } from "./category";
import { inventoryReducer } from "./inventory/index.js";
import { drawerTypeReducer } from "./drawer/index.jsx";
import { updateOrderStatus } from "./orders/updateOrderStatus.js";
import { countReducer } from "./home/countReducer.js";
const rootReducer = combineReducers({
    // auth
    login: loginReducer,
    currentDealor: currentDealorReducer,
    dealorShipLocations: dealorShipLocationsReducer,
    authOtpViaEmail: authOtpViaEmailReducer,
    authOtpViaSMS: authOtpViaSMSReducer,
    verifyAuthOtp: verifyAuthOtpReducer,
    forgetPasswordViaEmail: forgetPasswordViaEmailReducer,
    forgetPasswordViaSMS: forgetPasswordViaSMSReducer,
    resetPasswordVerifyOtp: resetPasswordVerifyOtpReducer,
    resetPassword: resetPasswordReducer,
    updateDealor: updateDealorReducer,
    logoutReducer: logoutReducer,
    currentUserReducer: currentUserReducer,
    // orders
    orders: ordersReducer,
    orderDetails: orderDetailsReducer,
    billInvoices: billInvoicesReducer,
    placeOrder: placeOrderReducer,
    dispatchStatusCount: dispatchStatusCountReducer,
    notificationCount: notificationCountReducer,
    ordersCount: ordersCountReducer,
    pendingOrdersCount: pendingOrdersCountReducer,
    uploadInvoice: uploadInvoiceReducer,
    // products
    products: productsReducer,
    prodcutCRUD:prodcutCRUDReducer,
    //dealorOnBoarding
    addApplicantReducer: addApplicantReducer,
    getApplicantSliceReducer:getApplicantSliceReducer,
    getEmailTempleteReducer:getEmailTempleteReducer,
    inputDrawingReducer: inputDrawingReducer,
    getUserDetailReducer:getUserDetailReducer,
    schematicLayoutByClusterReducer: schematicLayoutByClusterReducer,
    getLayoutFeedbackReducer:getLayoutFeedbackReducer,
    postSchematicLayoutSliceReducer:postSchematicLayoutSliceReducer,
    designEstimateReducer: designEstimateReducer,
    getAllFeedbackReducer:getAllFeedbackReducer,
    getGFC:GFCReducer,
    postGFC:postGFCReducer,
    revisedLayout:revisedLayoutReducer,
    revisedGFC: revisedGFCReducer,
    revisedLayoutViewReducer:revisedLayoutViewReducer,
    revisedGFCViewReducer: revisedGFCViewReducer,
    sendInvoiceReducer:sendInvoiceReducer,
    supplierReducer:supplierReducer,
    categoryReducer:categoryReducer,
    inventoryReducer:inventoryReducer,
    drawerTypeReducer:drawerTypeReducer,
    updateOrderStatus:updateOrderStatus,
    countReducer:countReducer
    
})

export default rootReducer