import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Card,  CardContent,  CircularProgress,  FormControl, FormHelperText, Grid, IconButton, InputAdornment,  OutlinedInput, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import Body from '../../components/Body';
import {  useNavigate } from 'react-router-dom';
import { formatDateString } from '../../utils/dateFormater';
import { showToast } from '../../toast/toast';
import "../../styles/custom.table.scss";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { acceptOrderApi, assignOrderApi, ordersApi, validateOrderApi } from '../../store/orders/orders';
import ItemRemark from '../reuseable/ItemRemark';
import Quotation from '../reuseable/QuotationModal';
import { createHtmlByArrayElement, isAllOrderAcceptedTrue } from '../../utils/orderItemArrayManipulation';
import AssignOrder from '../reuseable/AssignOrderModal';
import { truncateText } from '../reuseable/stringManipulation';
import { fixedPriceToDecimal } from '../../utils/priceManipulation';
import UpdateOrderStatusModal from '../reuseable/UpdateOrderStatusModal';
import RefundPaymentModal from '../reuseable/RefundPaymentModal';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
const View = () => {
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [viewData, setViewData] = useState({});
    const [isReset, setIsReset] = useState(false);
    const [errors, setErrors] = useState({});
    const [loadingStates, setLoadingStates] = useState({});
    const [mainOrderIdForRemark, setMainOrderIdForRemark] = useState();
    const [orderIdForRemark, setOrderIdForRemark] = useState();
    const [openRemarkModal, setOpenRemarkModal] = useState(false);
    const [notes, setNotes] = useState({});
    const [validated, setValidated] = useState({});
    const [openQuotationModal, setOpenQuotationModal] = useState(false);
    const [assignSubOrderId, setAssignSubOrderId] = useState();
    const [assignSupplierId, setAssignSupplierId] = useState();
    const [openAssignModal, setOpenAssignModal] = useState(false);
    const {loading:orderLoading, error:orderError, result:orderList}  = useSelector(state => state?.orders);    
    
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
    //Validate, accept and review order
    const setAllNoteEmpty = () => {
        setIsReset(!isReset);
    }

    const handleNoteChange = (subOrderId, value) => {
        setNotes(prev => ({
            ...prev,
            [subOrderId]: value
        }));
    };
    const handleValidation = async (subOrderId) => {
        if (!notes[subOrderId]) {
            setErrors(prev => ({
                ...prev,
                [subOrderId]: 'Note is required'
            }));
            return;
        } else {
            setErrors(prev => ({
                ...prev,
                [subOrderId]: ''
            }));
        }

        const dataToSent = {
            sub_order_id: subOrderId,
            note: notes[subOrderId],
        }

        try {
            setLoadingStates((prevState) => ({ ...prevState, [subOrderId]: true }));
            const response = await dispatch(validateOrderApi(dataToSent));
            if (response?.payload?.status !== 'error') {
                showToast("Order validated successfully", 1);
                dispatch(ordersApi());
                setTimeout(() => {
                    setLoadingStates((prevState) => ({ ...prevState, [subOrderId]: false }));
                }, 1000);
            } else {
                showToast("Failed to validate order", 2);
                setLoadingStates((prevState) => ({ ...prevState, [subOrderId]: false }));
            }
        } catch (err) {
            showToast(err.message, 2);
        }

        setValidated(prev => {
            const updatedValidated = {
                ...prev,
                [subOrderId]: !prev[subOrderId] // Toggle validation status
            };
            return updatedValidated;
        });
    };

    const acceptOrder = (subOrderId) => {
        const dataToSent = {
            sub_order_id: subOrderId,
        }
        try{
            const response = dispatch(acceptOrderApi(dataToSent));
            if(response?.payload?.status !== 'error') {
                showToast("Order accepted successfully",1);
                setTimeout(() => {
                    dispatch(ordersApi());
                }, 2000);
            }else{
                showToast("Failed to accept order", 2);
            }
        }catch(err){
            showToast(err, 2);
        }
    }
    
    const remarkItem = (mainOrderId, subOrderId) => {
        setMainOrderIdForRemark(mainOrderId)
        setOrderIdForRemark(subOrderId)
        setOpenRemarkModal(true)
    }

    
    const uploadQuotation = (subOrderId) => {
        setOrderIdForRemark(subOrderId)
        setOpenQuotationModal(true)
    }

    const [refundMainOrderId, setRefundMainOrderId] = useState();
    const [openRefundReturnModal, setOpenRefundReturnModal] = useState(false);
    const returnRefund = (mainOrderId) => {
        setOpenRefundReturnModal(true)
        setRefundMainOrderId(mainOrderId)
    }
    
    const closeRefundReturnModal = () => {
        setOpenRefundReturnModal(false)
        setRefundMainOrderId()
    }
    
    const assignOrder = (subOrderId, companyId) => {
        /*The below code can be implement for email template
        setAssignSubOrderId(subOrderId)
        setAssignSupplierId(companyId)
        setOpenAssignModal(true)
*/
setOpenAssignModal(true)
        // const dataToSent = {
        //     sub_order_id: subOrderId,
        //     supplier_company_id: companyId

        // }
        // try{
        //     const response = dispatch(assignOrderApi(dataToSent));
        //     if(response?.payload?.status !== 'error') {
        //         showToast("Order assigned successfully",1);
        //         setTimeout(() => {
        //             dispatch(ordersApi());
        //         }, 2000);
        //     }else{
        //         showToast("Failed to assign order", 2);
        //     }
        // }catch(err){
        //     showToast(err, 2);
        // }
        
    }
    const closeAssignModal = () => {
        setOpenAssignModal(false)
        setAssignSubOrderId()
        setAssignSupplierId()
    }

    const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
    const [updateStatusOrderId, setUpdateStatusOrderId] = useState();
    const updateStatus = (subOrderId) => {
        setUpdateStatusOrderId(subOrderId)
        setOpenUpdateStatusModal(true)
    }
    const handleCloseUpdateStatusModal = () => {
        setOpenUpdateStatusModal(false)
        setUpdateStatusOrderId()
    }
      
    useEffect(() => {
        if(localStorage.getItem("selectedOrderIdView") && orderList?.data) {
            const datad = orderList?.data?.filter((item) => item?.id === Number(localStorage.getItem("selectedOrderIdView")));
            setViewData(datad);
        }
        
    }, [localStorage.getItem("selectedOrderIdView"), orderList, isReset]);


    useEffect(() => {
        if (viewData[0]?.order_items) {
            const initialNotes = viewData[0].order_items.reduce((acc, item) => {
                acc[item.id] = item.note;
                return acc;
            }, {});
            setNotes(initialNotes);
            
            const initialValidated = viewData[0].order_items.reduce((acc, item) => {
                acc[item.sub_order_id] = false;
                return acc;
            }, {});
            setValidated(initialValidated);
        }
    }, [viewData]);

    useEffect(() => {
        if (viewData[0]?.order_items) {
            const initialNotes = viewData[0].order_items.reduce((acc, item) => {
                acc[item.id] = "";
                return acc;
            }, {});
            setNotes(initialNotes);
        }
        setIsReset(false)
    }, [isReset]);
    
    return (
        <Body>
            <Stack
                direction="row"
                spacing={10}
                sx={{ my: 2 }}
                justifyContent="space-between"
                alignItems={"center"}
            >
                <Stack direction="row" alignItems="center">
                   <ArrowBackIosIcon className='cursor-pointer' onClick={() => navigate(-1)}/>
                </Stack>
            </Stack>
            <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box className="flex-wrap" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                            <Box sx={{ borderRight: "1px solid #D4E0EC !important", paddingRight: "14px" }}>
                                <Typography variant="h5" className="fs-14 fw-400">Main Order ID</Typography>
                                <Typography variant="h5" className="fs-15 fw-600" sx={{ color: "#157DC9 !important" }}>
                                    {viewData?.[0]?.main_order_id}
                                </Typography>
                            </Box>
                            <Box sx={{ paddingLeft: "14px" }}>
                                <Typography variant="h5" className="fs-15 fw-400">{viewData?.[0]?.ordered_by_user_name}</Typography>
                                <Typography variant="h5" className="fs-15 fw-600">{viewData?.[0]?.cluster_name}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ paddingLeft: "20px", flexShrink: 0,justifyContent:"center" }}>
                            <Button
                                className={viewData?.[0]?.order_quotations.length > 0 ? 'success-btn my-auto fw-600' : 'white-btn my-auto fw-600'}
                                sx={{ fontSize: "12px !important" }}
                                disabled={viewData?.[0]?.order_quotations.length > 0 || (Array.isArray(viewData?.[0]?.order_items) && !isAllOrderAcceptedTrue(viewData?.[0]?.order_items)) || viewData?.[0]?.order_cancelled}
                                onClick={() => uploadQuotation(viewData?.[0]?.id)}
                            >
                                {viewData?.[0]?.order_quotations.length > 0 ? "Quotation Sent" : "Upload Quotation"}
                            </Button>
                        </Box>
                        {viewData?.[0]?.refund_approved_by_admin && viewData?.[0]?.refund_paid_by_innoceans===false && (
                            <Box sx={{ paddingLeft: "20px", flexShrink: 0,justifyContent:"center" }}>
                                <Button
                                    className={viewData?.[0]?.refund_paid_by_innoceans ? 'white-btn my-auto fw-600' : 'black-btn my-auto fw-600'}
                                    sx={{ fontSize: "12px !important" }}
                                    onClick={() => returnRefund(viewData?.[0]?.id)}
                                    title='Click to view Refund Initiated'
                                    disabled={viewData?.[0]?.refund_paid_by_innoceans}

                                >
                                    Return Refund
                                </Button>
                            </Box>
                        )}

                        {viewData?.[0]?.refund_approved_by_admin && viewData?.[0]?.refund_paid_by_innoceans && (
                            <Box sx={{ paddingLeft: "20px", flexShrink: 0,justifyContent:"center" }}>
                                <Button
                                    className={viewData?.[0]?.refund_paid_by_innoceans ? 'white-btn my-auto fw-600' : 'black-btn my-auto fw-600'}
                                    sx={{ fontSize: "12px !important" }}
                                    onClick={() => returnRefund(viewData?.[0]?.id)}
                                    title='Click to view Refund Initiated'
                                    disabled={viewData?.[0]?.refund_paid_by_innoceans}

                                >
                                    Refund Paid
                                </Button>
                            </Box>
                        )}
                    </Box>
                    {viewData?.[0]?.order_status && (
                                <>
                                <Box className="d-flex gap-10 mt-10" sx={{alignItems:"center"}} >
                                    <Typography variant="label" fontWeight={600}>Order Status:</Typography>
                                    <Typography variant="label" fontWeight={600} sx={{color: "#157dc9 !important"}}>{viewData?.[0]?.order_status}</Typography>
                              </Box>
                                </>
                            )}
                            {viewData?.[0]?.order_cancellation_comment && (
                                <>
                                <Grid item xs={12} sm={12} md={12} lg={12} className="mt-5">
                                    <Typography>Cancellation Reason: {viewData?.[0]?.order_cancellation_comment}</Typography>
                                </Grid>
                                </>
                            )}
                </Grid>
            </Grid>

                {viewData[0]?.order_items?.map((items, index) => (
                   <>
                   <Grid container spacing={1} className='mt-30' key={index}>
                    <Grid item xs={12}>
                        <Card className="order-card">
                            <CardContent className='py-0'>
                                <Grid container sx={{ borderBottom: "3px solid #E6F1FC", paddingBlock: "15px !important" }}>
                                    <Grid item xs={12} md={2}>
                                        <Box>
                                        {items?.product_snap ? <>
                                            <img style={{ maxWidth: "90%",height:"100%",maxHeight:"100px" }} src={`${process.env.REACT_APP_BASE_URL}${items?.product_snap}`} alt="tiles"  />
                                        </> : <>
                                            <Box>
                                                No Image
                                            </Box>
                                        </>}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Box className="d-flex w-100">
                                            <table className='w-100' rules='all'>
                                                <tbody>
                                                    <tr>
                                                        <td className='' style={{width:"300px"}}>
                                                            <Box className="w-100 pb-16">
                                                                <Box className="d-flex w-100">
                                                                    <Box className="fw-600" sx={{mr:"10px"}}>
                                                                        Sub Order ID:
                                                                    </Box>
                                                                    <Box className="fw-400">
                                                                        {items?.sub_order_id} {items?.sub_order_status ? `(${items?.sub_order_status})` : ""} 
                                                                    </Box>
                                                                </Box>
                                                
                                                            </Box>
                                                        </td>
                                                        <td className=''>
                                                            <Box className="w-100 pb-16">
                                                                <Box className="d-flex w-100">
                                                                    <Box  className="fw-600" sx={{mr:"10px"}}>
                                                                        Order Date:
                                                                    </Box>
                                                                    <Box className="no-border fw-400">
                                                                        {formatDateString(viewData[0]?.order_date)}
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='' style={{width:"300px"}}>
                                                            <Box className="w-100 pb-16 pt-16">
                                                    
                                                                <Box className="d-flex w-100">
                                                                    <Box className="fw-600" sx={{mr:"10px"}}>
                                                                        Supplier Name:
                                                                    </Box>
                                                                    <Box className="fw-400 d-flex">
                                                                        <span>{items?.supplier} | {items?.category}</span>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </td>
                                                        <td className=''>
                                                            <Box className="w-100 pb-16 pt-16">
                        
                                                                <Box className="d-flex w-100">
                                                                    <Box className="fw-600" sx={{mr:"10px"}}>
                                                                        Order Reason:
                                                                    </Box>
                                                                    <Box className="no-border fw-400">
                                                                        {viewData[0]?.purpose}
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </td>
                                                        
                                                    </tr>
                                                    {/* {createHtmlByArrayElement(viewData[0]?.order_status)} */}
                                                </tbody>
                                            </table>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ borderBottom: "3px solid #E6F1FC", paddingBlock: "15px !important" }}>
                                    <Grid item xs={12}>
                                        <TableContainer className="solidtable" sx={{ maxHeight: 440 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ background: "#85949F !important", color: '#ffffff !important',width:"300px" }} align="center">Product Description</TableCell>
                                                        <TableCell sx={{ background: "#85949F !important", color: '#ffffff !important' }} align="center">Product Category</TableCell>
                                                        <TableCell sx={{ background: "#85949F !important", color: '#ffffff !important' }} align="center">Snap</TableCell>
                                                        <TableCell sx={{ background: "#85949F !important", color: '#ffffff !important' }} align="center">Item Price</TableCell>
                                                        <TableCell sx={{ background: "#85949F !important", color: '#ffffff !important' }} align="center">Qty</TableCell>
                                                        <TableCell sx={{ background: "#85949F !important", color: '#ffffff !important' }} align="center">Total Amount</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className="cstm-tablecell1" align='center' title={items?.product_description}>
                                                            {truncateText(items?.product_description, 40) || items?.product_description}
                                                        </TableCell>
                                                        <TableCell className="cstm-tablecell1" align="center">
                                                            {items?.category}
                                                        </TableCell>
                                                        <TableCell className="cstm-tablecell1" align="center">
                                                        {items?.product_snap ? <>
                                                            <img style={{ maxWidth: "90%",height:"100%",maxHeight:"100px" }} src={`${process.env.REACT_APP_BASE_URL}${items?.product_snap}`} alt="tiles"  />
                                                        </> :
                                                                'No Image'
                                                        }
                                                        </TableCell>
                                                        <TableCell className="cstm-tablecell1" align="center">
                                                            {(fixedPriceToDecimal(items?.price, 2))/items?.quantity}
                                                        </TableCell>
                                                        <TableCell className="cstm-tablecell1" align="center">
                                                            {items?.quantity}
                                                        </TableCell>
                                                        
                                                        <TableCell className="cstm-tablecell1" align="center">
                                                            {fixedPriceToDecimal(items?.price, 2) }
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='h4' className='fs-15 fw-300 link mt-10 mb-10'>
                                            <BorderColorOutlinedIcon color='#05141F' className='fs-13' />&nbsp; Add a note to your order
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} className='flex-wrap orderValidate'>
                                        <Box sx={{height:"min-content" , display:"flex",alignItems: "start"}}>
                                            
                                            <FormControl sx={{ mr: 1, width: '50%' }} variant="outlined">
                                                <OutlinedInput
                                                    className='no-legend checkinputfield'
                                                    id="outlined-adornment"
                                                    disabled={items?.note || viewData[0]?.order_cancelled || viewData[0]?.is_remark}
                                                    value={notes[items.id]}
                                                    onChange={(e) => handleNoteChange(items.id, e.target.value)}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle validation"
                                                                //onClick={() => handleValidation(items.id)}
                                                                edge="end"
                                                            >
                                                                <CheckCircleIcon color={validated?.[items.id] && items?.order_validation ? 'success' : 'default'} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                {errors[items.id] && <FormHelperText error>{errors[items.id]}</FormHelperText>}
                                            </FormControl>
                                            <Button
                                                variant="contained"
                                                className={items?.order_validation === true ? 'success-btn fw-600' : 'black-btn fw-600'}
                                                sx={{ fontSize: "12px !important" }}
                                                disabled={items?.order_validation === true || loadingStates[items.id] || viewData[0]?.order_cancelled || viewData[0]?.is_remark}
                                                onClick={() => handleValidation(items.id)}
                                            >
                                            {loadingStates[items.id] ? <CircularProgress size={24} color="inherit" /> : items?.order_validation ? "Order Validated" : "Validate Order"}
                                            </Button>
                                        </Box>
                                        
                                    </Grid>
                                    <Grid item xs={12} md={6} className='flex-wrap gap-20 justify-end' sx={{alignItems:"start"}}>
                                        <Button className='white-btn fw-600' sx={{ fontSize: "13px !important" }} disabled={items?.order_accepted===true || viewData[0]?.order_cancelled || viewData[0]?.is_remark} onClick={() => remarkItem(viewData[0]?.id,items.id)}>
                                            Review Order
                                        </Button>
                                        <Button className={items?.order_accepted ? 'success-btn fw-600' : 'black-btn fw-600'} sx={{ fontSize: "12px !important" }} disabled={!loadingStates?.[items.id] && items?.order_validation===false || items?.order_accepted===true || viewData[0]?.order_cancelled || viewData[0]?.is_remark} onClick={() => acceptOrder(items.id)}>
                                            {items?.order_accepted ? "Order Accepted" : "Accept Order"}
                                        </Button>
                                        {/* {items?.order_accepted &&
                                         <Button 
                                         className={items?.assign_supplier!==null ? 'success-btn fw-600' : 'black-btn fw-600'}
                                          sx={{ fontSize: "13px !important" }} onClick={() => assignOrder(items.id, items?.supplier_id)}>
                                            {items?.assign_supplier!==null ? "Order Assigned" : "Assign Order"}
                                        </Button>
                                        } */}

                                        <Button disabled={items.order_accepted===false  || viewData[0]?.order_cancelled || viewData[0]?.payments.length===0 || viewData[0]?.invoices.length===0} className='black-btn fw-600' sx={{ fontSize: "12px !important" }}  onClick={() => updateStatus(items.id)}>
                                            Update Dispatch Status
                                        </Button>

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                   </>
                ))}
                
            </Box>
            <ItemRemark open={openRemarkModal} handleClose={() => setOpenRemarkModal(false)} subOrderId={orderIdForRemark} mainOrderId={mainOrderIdForRemark} setAllNoteEmpty={setAllNoteEmpty}/>
            <Quotation open={openQuotationModal} handleClose={() => setOpenQuotationModal(false)} subOrderId={orderIdForRemark}/>
            <AssignOrder open={openAssignModal} handleClose={closeAssignModal} subOrderId={assignSubOrderId} supplierId={assignSupplierId}/>
            <UpdateOrderStatusModal open={openUpdateStatusModal} handleClose={handleCloseUpdateStatusModal} subOrderId={updateStatusOrderId}/>
            <RefundPaymentModal open={openRefundReturnModal} handleClose={closeRefundReturnModal} orderId={refundMainOrderId} />
        </Body>
        
    );
}
export default View
