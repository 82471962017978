import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  TextareaAutosize,
} from "@mui/material";
import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { sendFeedback } from "../../store/home/sendFeedback";

import { showToast } from "../../toast/toast";
const useStyles = makeStyles((theme) => ({
  bgLightGray: {
    backgroundColor: "rgba(213, 222, 229, 1)",
  },
  borderRadius10: {
    borderRadius: "10px",
  },
  p10: {
    padding: "10px",
  },
  p20: {
    padding: "20px",
  },
  buttonsStyle: {
    backgroundColor: "#D7EDFE",
  },
  fullWidth: {
    width: "100%",
  },
}));

const Feedback = ({ open, handleClose, id, userType, feedBackOn, getInputDrawing }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState("");
  const [feedbackError, setFeedbackError] = useState(false);
  const formId = useSelector(
    (state) => state?.inputDrawingReducer?.result?.data[0]
  );

  const handleChange = (event) => {
    const { value } = event.target;
    if (value === "") {
      setFeedbackError(true);
    } else {
      setFeedbackError(false);
    }
    setFeedback(event.target.value);
  };
  const sendFeedbackSubmit = async () => {
    if (feedback === "") {
      setFeedbackError(true);
      return;
    }
    const formData = new FormData();
    formData.append("form_id", id);
    formData.append("user_type", userType);
    formData.append("feedback", feedback);
    formData.append("feedback_on", feedBackOn);
    formData.append("added_for", 'KIA');

    await dispatch(sendFeedback(formData))
      .then((data) => {
        showToast(data?.payload?.message, 1);
        getInputDrawing()
        handleClose();
      })

      .catch((error) => {
        console.error("Error editing email template:", error);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <CardHeader
            sx={{ textAlign: "center" }}
            action={
              <IconButton onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
            }
            title="Raise a Feedback"
          />
          <Box
            style={{
              padding: "10px",
              maxWidth: "500px",
              margin: "0 auto",
            }}
          >
            <CardContent className="d-flex" sx={{paddingInline: 0}}>
              <TextareaAutosize
                style={{
                  width: "100%",
                  border: "1px solid #C4D3DF",
                  borderRadius: "5px",
                  padding: "10px",
                }}
                className="bg-white mx-auto"
                minRows={6}
                placeholder={"Enter your Feedback"}
                value={feedback}
                onChange={handleChange}
              />
              
            </CardContent>
            <span>
              {feedbackError && (
                <p className="text-danger">Please enter your Feedback</p>
              )}
            </span>
            <CardActions disableSpacing sx={{paddingInline: 0}}>
              <Box
                component={"div"}
                width={"100%"}
                marginBlockStart={0.5}
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
                gap={2}
              >
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    color: "rgba(167, 177, 187, 1)",
                    fontSize: "20px",
                    fontWeight: 600,
                    textDecoration: "underline",
                    textTransform: "none",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => sendFeedbackSubmit()}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: 1.8,
                    boxShadow: "none",
                    backgroundColor: "#000 !important",
                    textTransform: "none",
                  }}
                  className={classes.buttonsStyle}
                >
                  Send
                </Button>
              </Box>
            </CardActions>
          </Box>
        </Card>
      </Modal>
    </>
  );
};

export default Feedback;
