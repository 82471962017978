import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Box, Tooltip, Typography, CardHeader, IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { handleDownload } from './downloadFile';
import pdf from '../../assets/img/pdf.png'
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function DownloadFileModal({ openView, handleCloseView , fileArray, type}) {
    
    const handleClose = () => {
        handleCloseView();
    }
    return (
        <React.Fragment>
            <Dialog
                open={openView}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    <CardHeader
                        className="popup-header-rev"
                        action={
                        <IconButton onClick={handleClose}>
                            <HighlightOffIcon />
                        </IconButton>
                        }
                        title={
                        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                            <Typography variant="h6">Download File</Typography>
                        </Box>
                        }
                    />
                    <Divider sx={{ width: '100%', mt: 1, mb: 2 }} />
                </DialogTitle>
                <DialogContent>
                    <Grid container alignItems="center" spacing={2}>
                            <Box sx={{ width: "213px" }}>
                                <Box className="upload-box bg-white text-center">
                                    <Box className="m-auto p-relative">

                                    {fileArray?.map((data) => (
                                        <>
                                            <Box className="text-center">
                                                <Box className="w-100 p-relative">
                                                    <Tooltip title={data.files} placement="top" className={` uploadedfilename`} >
                                                        {/* <img width={40} height={40} src={cad} className="my-auto" /> */}
                                                        <Typography>
                                                           {`${type} File`} 
                                                        </Typography>
                                                        <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', data.files)} />
                                                        <FullscreenIcon className="my-auto" onClick={() => window.open(`https://kiastageapi.dev.vinove.com${data.files}`)} />
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        </>
                                    ))}

    
                                    </Box>
                                </Box>
                            </Box>
                    </Grid>
                </DialogContent>
                {/* <Divider sx={{ width: '100%', mb: 2 }} />
                <DialogActions
                    sx={{ display: 'flex', justifyContent: 'center', gap: 2, p: 2 }}
                >
                    <Button
                        onClick={handleClose}
                        color='error'
                        sx={{ backgroundColor: '#f44336', color: '#fff', '&:hover': { backgroundColor: '#d32f2f' } }}
                    >
                        Close
                    </Button>
                </DialogActions> */}
            </Dialog>
        </React.Fragment>
    );
}