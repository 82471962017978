import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    createTheme,
    Divider,
    FormHelperText,
    Grid,
    IconButton,
    MenuItem,
    Modal,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderStatusSchema } from "../validationSchemas";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { convertDateFunctionYMD } from "../../utils/dateFormater.jsx";
import { updateOrderStatus } from "../../store/orders/updateOrderStatus.js";
import { showToast } from "../../toast/toast.jsx";
import { orderStatus } from "../../utils/orderStatus.js";
import { ordersApi } from "../../store/orders/orders.js";

const UpdateOrderStatusModal = ({ open, handleClose, subOrderId }) => {
const dispatch = useDispatch();
const [updateStatusRequest, setUpdateStatusRequest] = useState()
const [errors, setErrors] = useState({});
const [stage, setStage] = useState(1)
const [dispatch_date, setDispatchDate] = useState(null);
const [isloading, setIsLoading] = useState(false);
const theme = createTheme({
palette: {
  secondary: {
    main: "#D3E1EF",
  },
},
});

const handleChange = (event) => {
    const { name, value } = event.target;
    if(value === "") {
        setUpdateStatusRequest((preValue) => {
            return {
                ...preValue,    
                [name]: ''
            }
        })
    }else{
        setUpdateStatusRequest((preValue) => {
            return {
                ...preValue,    
                [name]: value
            }
        })
    }
    updateError(name);

};
const updateError = (name)=>{
    setErrors((preValue) => {
        return {
            ...preValue,
            [name] : ""
        }
    })
}
const handleSubmit = (e) => {
    e.preventDefault();
   
    const mergedErrors = { ...errors };
    updateOrderStatusSchema.validate(updateStatusRequest, { abortEarly: false })
        .then(() => {
            addRequestAPI();
            setErrors({});
        })
        .catch((validationErrors) => {
            const newErrors = {};
            validationErrors.inner.forEach((error) => {
                newErrors[error.path] = error.message;
            });
            setErrors({ ...mergedErrors, ...newErrors });
            //showToast("Please fill mandatory fields", 2)
        });
};

const addRequestAPI = async () => {
    setIsLoading(true);
    try {
        const formData = new FormData();
        formData.append("sub_order_id", subOrderId);
        formData.append("status", updateStatusRequest?.dispatch_status);
        formData.append("transportation_number", updateStatusRequest?.transportation_number ? updateStatusRequest?.transportation_number : "");
        //formData.append("date", updateStatusRequest?.dispatch_date);
        formData.append("delivery_person_name", updateStatusRequest?.delivery_person_name ? updateStatusRequest?.delivery_person_name : "");
        formData.append("delivery_contact_number", updateStatusRequest?.delivery_contact_number ? updateStatusRequest?.delivery_contact_number : "");  
        await dispatch(updateOrderStatus(formData))
        .then((data) => {
            if(data.payload?.error){
                showToast(data?.payload?.error, 2);
                return
            }
            handleCancel();
            showToast(data?.payload?.message, 1);
            setTimeout(() => {
                dispatch(ordersApi());
            }, 2000);
            //showToast('Order status updated successfully', 1)
            
        })
        .catch((error) => {
          console.error("Error sending feedback:", error);
        });
        //showToast('Order status updated successfully', 1)
        //dispatch(getInventoryApi())
        setIsLoading(false)
        //handleCancel();
    } catch (error) {
        showToast(error, 2);
    }
    
  }

useEffect(() => {
    if (dispatch_date !== null) {
        setUpdateStatusRequest((preValue) => {
            return {
                ...preValue,
                'dispatch_date': convertDateFunctionYMD(dispatch_date)
            }
        })
    }
    updateError("dispatch_date");

}, [dispatch_date])


const resetForm = () => {
  setUpdateStatusRequest({
    'dispatch_date': '',
    'dispatch_status': '',
    'transportation_number': '',
    'delivery_person_name': '',
    'delivery_person_contact': '',
  })
  updateError("dispatch_date");
  updateError("dispatch_status");
};

const handleCancel = () => {
  resetForm();
  handleClose();
};




return (
    <Modal
        open={open}
        onClose={handleCancel}
        className={ModalCss?.customModal}
        BackdropProps={{
            style: { pointerEvents: "none" }, // Disable click events on the backdrop
        }}
       
        sx={{
    '& .MuiModal-paper': {
      width: '100%',  // Adjust width as needed
      maxWidth: '1200px',  // Set a maximum width
      height: '80%',  // Adjust height as needed
      maxHeight: '800px',  // Set a maximum height
      margin: 'auto',
      borderRadius: '8px',
      padding: 2
    },
  }}
    >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
            <CardHeader
                className="popup-header-rev"
                action={
                <IconButton onClick={handleCancel}>
                    <HighlightOffIcon />
                </IconButton>
                }
                title={
                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <Typography variant="h6">Update Dispatch Status</Typography>
                </Box>
                }
            />
            <Divider />


            <CardContent sx={{ overflowY: 'auto', maxHeight: '60vh' }}>
                <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <label>Dispatch Status <span style={{ color: "red" }}>*</span></label>
                                    <TextField
                                        select
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        name="dispatch_status"
                                        value={updateStatusRequest?.dispatch_status}
                                        sx={{ marginTop: "10px"}}
                                    >
                                        <MenuItem value={orderStatus.order_packed}>{orderStatus.order_packed}</MenuItem>
                                        <MenuItem value={orderStatus.order_dispatched}>{orderStatus.order_dispatched}</MenuItem>
                                        <MenuItem value={orderStatus.order_shipped}>{orderStatus.order_shipped}</MenuItem>
                                        <MenuItem value={orderStatus.order_delivered}>{orderStatus.order_delivered}</MenuItem>
                                    </TextField>
                                    <FormHelperText error>
                                        {errors?.dispatch_status}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <label>Dispatch Date <span style={{ color: "red" }}>*</span></label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={["DatePicker"]} sx={{ marginTop: "10px",padding: "0px" }}>
                                        <DatePicker
                                                    name="dob"
                                                    minDate={dayjs()}
                                                    value={updateStatusRequest?.dispatch_date ? dayjs(updateStatusRequest?.dispatch_date) : ""}
                                                    slotProps={{ textField: { fullWidth: true } }}
                                                    onChange={(newValue) => {
                                                        setDispatchDate(newValue)
                                                        updateError('dob')
                                                    }
                                                    }

                                                />
                                    
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    <FormHelperText error>
                                        {errors?.dispatch_date}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <label>Transportation Number</label>
                                    <TextField
                                        onChange={handleChange}
                                        name="transportation_number"
                                        value={updateStatusRequest?.transportation_number}
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{ maxLength: 50 }}
                                        sx={{ marginTop: "10px"}}
                                    />
                                    <FormHelperText error>
                                        {errors?.transportation_number}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <label>Delivery Person Name</label>
                                    <TextField
                                        onChange={handleChange}
                                        name="delivery_person_name"
                                        value={updateStatusRequest?.delivery_person_name}
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{ maxLength: 15 }}
                                        sx={{ marginTop: "10px"}}
                                    />
                                    <FormHelperText error>
                                        {errors?.delivery_person_name}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <label>Delivery Contact Number</label>
                                    <TextField
                                        onChange={handleChange}
                                        name="delivery_contact_number"
                                        value={updateStatusRequest?.delivery_contact_number}
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 10,
                                        }}  
                                        sx={{ marginTop: "10px"}}
                                    />
                                    <FormHelperText error>
                                        {errors?.delivery_contact_number}
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                            
                
                

                

                <Box component={"div"}>
                    <Grid container py={6}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            gap={2}
                            sx={{ display: "flex", justifyContent: "center", gap: 2 }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    py: 2,
                                    px: 4,
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    boxShadow: "none",
                                }}
                               type="submit"
                               disabled={isloading}
                            >
                               {isloading? "Updating..." : "Update"}
                            </Button>
                    
                        </Grid>
                    </Grid>
                </Box>
                </form>
                
            </CardContent>
        </Card>
  </Modal>
  
  );
};

export default UpdateOrderStatusModal;
