import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import { getAccessToken } from "../../utils/getAccessToken";


export const refundOrderApi = createAsyncThunk(
    'order/refundOrder',
    async (data, { rejectWithValue , getState}) => {
        try {
            const accessToken = getAccessToken(getState);
            const response = await AuthAxios.post(`/api/v1/order/add_refund_details/`, data,
                {
                    headers: {'Authorization': `Bearer ${accessToken}`
                    
                }          
                
            })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const refundOrderSlice = createSlice({
    name: 'refundOrderApi',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(refundOrderApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(refundOrderApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(refundOrderApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const refundOrderReducer = refundOrderSlice.reducer