import { Box } from "@mui/material";
import { formatDateString } from "./dateFormater";

export const  isAllOrderValidationTrue = (orderItems) => {
    for (let item of orderItems) {
      if (!item.order_validation) {
        return false;
      }
    }
    return true;
}


export const  isAllOrderAcceptedTrue = (orderItems) => {
  for (let item of orderItems) {
    if (!item.order_accepted) {
      return false;
    }
  }
  return true;
}

export const countSchematicAvailable = (orderItems) => {
  if (!Array.isArray(orderItems)) {
    return 0; // Return 0 if orderItems is undefined, null, or not an array
  }

  let count = 0;
  for (let item of orderItems) {
    if (item.schematic_status) {
      count++;
    }
  }
  return count;
}

export const countGFCAvailable = (orderItems) => {
  if (!Array.isArray(orderItems)) {
    return 0; // Return 0 if orderItems is undefined, null, or not an array
  }

  let count = 0;
  for (let item of orderItems) {
    if (item.gfc_status) {
      count++;
    }
  }
  return count;
}


export const countApprovalAdmin = (orderItems) => {
  if (!Array.isArray(orderItems)) {
    return 0; // Return 0 if orderItems is undefined, null, or not an array
  }

  let count = 0;
  for (let item of orderItems) {
    if (item.approve_by_admin) {
      count++;
    }
  }
  return count;
}


// export const countSchematicAvailable = (orderItems) => {
//   let count = 0;
//   for (let item of orderItems) {
//     if (item.schematic_status) {
//       count++;
//     }
//   }
//   return count;
// }

export const createHtmlByArrayElement = (arr) => {
  if (arr.length === 0) {
    return null; 
  }
  const newD = arr[arr.length-1];
  return <>
    <tr>
      <td className='w-50'>
        <Box className="w-100 pt-16 pb-16">

          <Box className="d-flex w-100">
            <Box className="fw-600" sx={{mr:"10px"}}>
              Transportation Number:

            </Box>
            <Box className="fw-400">
              {newD?.transportation_number ? newD?.transportation_number : "N/A"}
            </Box>
          </Box>
        </Box>
      </td>
      <td className='w-50'>
        <Box className="w-100 pt-16 pb-16">

          <Box className="d-flex w-100">
            <Box className="fw-600" sx={{mr:"10px"}}>
              Date:
            </Box>
            <Box className="w-50 no-border fw-400">
              {newD?.date ? formatDateString(newD?.date) : "N/A"}
            </Box>
          </Box>
        </Box>
      </td>
    </tr>
    <tr>
      <td className='w-50'>
        <Box className="w-100 pt-16 pb-16">

          <Box className="d-flex w-100">
            <Box className="fw-600" sx={{mr:"10px"}}>
            Delivery Person Name:

            </Box>
            <Box className="fw-400">
              {newD?.delivery_person_name ? newD?.delivery_person_name : "N/A"}
            </Box>
          </Box>
        </Box>
      </td>
      <td className='w-50'>
        <Box className="w-100 pt-16 pb-16">

          <Box className="d-flex w-100">
            <Box className="fw-600" sx={{mr:"10px"}}>
            Delivery Person Contact:
            </Box>
            <Box className="no-border fw-400">
              {newD?.delivery_contact_number ?newD?.delivery_contact_number : "N/A"}
            </Box>
          </Box>
        </Box>
      </td>
    </tr>
  </>
};