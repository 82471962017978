export const calculateTotal = (orderItems) => {
  return parseFloat(orderItems.reduce((total, item) => {
    if (item.quantity && item.price) {
      return total + (item.quantity * item.price);
    }
    return total;
  }, 0).toFixed(2));
};

export const calculateTotalPaidAmount = (orderItems) => {
  return parseFloat(orderItems.reduce((total, item) => {
    if (item.amount_paid) {
      return total + parseFloat(item.amount_paid); // convert string to float
    }
    return total;
  }, 0).toFixed(2)); // Ensure the result is rounded to 2 decimal places
};

export const  fixedPriceToDecimal =(x, upto)=> {
  return Number.parseFloat(x).toFixed(upto);
}